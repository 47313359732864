import React from "react"

import Layout, { Body } from "../Shared/Layout"
import ContactUsForm from "../Shared/ContactUsForm"
import Icon from "../Shared/Icon"
import SEO from "../Shared/Seo"
import {gtagWrapper} from "../../utils/gtagHelper";

import styles from "./ContactUsPage.module.scss"

const ContactUsPage = () => {
  return (
    <Layout>
      <SEO
        title="Learn more about Aramaze and Contact Us"
        description="We're always here to help. Contact us today."
      />
      <Body>
        <div className={styles.wrap}>
          {/*           <h2>Contact Us</h2> */}
          <div className={styles.contactCol3}>
            <div className={styles.contactItem}>
              <div className={styles.contactIcon}>
                <i>
                  <Icon icon="location" size={20} />
                </i>
              </div>
              <div className={styles.contactText}>
                <h2>Location:</h2>
                <p>PO Box 7101</p>
                <p>Prospect, CT 06712</p>
                <p>United States</p>
              </div>
            </div>
            <div className={styles.contactItem}>
              <div className={styles.contactIcon}>
                <i>
                  <Icon icon="envelop" size={20} />
                </i>
              </div>
              <div className={styles.contactText}>
                <h2>Email:</h2>
                <a href="mailto:contact@aramaze.com" onClick={()=>gtagWrapper('event', 'click-email', { 'event_category': 'click', })}>contact@aramaze.com</a>
              </div>
            </div>
            <div className={styles.contactItem}>
              <div className={styles.contactIcon}>
                <i>
                  <Icon icon="phone" size={20} />
                </i>
              </div>
              <div className={styles.contactText}>
                <h2>Call:</h2>
                <a href="tel:+12035275119" onClick={()=>gtagWrapper('event', 'click-phone-number', { 'event_category': 'click', })}>+1 (203) 527-5119</a>
              </div>
            </div>
          </div>
          <ContactUsForm />
        </div>
      </Body>
    </Layout>
  )
}

export default ContactUsPage
